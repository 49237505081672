import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import { useGetBusinessMemberLeaderboard } from "../../auth/hooks"
import { Filter, Table as TableBase } from "../../components"
import { TableHeading } from "../../components/Table"
import EvolutionIndicator from "./EvolutionIndicator"
import RankingIndicator from "./RankingIndicator"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "position associate evolution"
      ". merchantCount tpv";
    align-items: start;
    row-gap: 10px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-areas: "position associate evolution merchantCount tpv";
      align-items: center;
    }
  }
`

const AssociateContainer = styled.div`
  grid-area: associate;

  .associate-name {
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .associate-email {
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const RowTextField = styled.p<{
  gridArea: string
}>`
  grid-area: ${({ gridArea }) => gridArea};
  padding-right: 5px;
  line-height: 22px;
  text-align: left;

  &.mobile-align-right {
    text-align: right;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    &.mobile-align-right {
      text-align: left;
    }
  }
`

const ActivatedMerchantsCount = styled(RowTextField)`
  font-size: 12px;

  b {
    font-size: 14px;
  }

  .show-on-mobile {
    display: inline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;

    .show-on-mobile {
      display: none;
    }
  }
`

interface FilterOption {
  title: string
  filterRef: "descending" | "ascending"
}

const leaderboardFilters: FilterOption[] = [
  {
    title: "Descending Order",
    filterRef: "descending",
  },
  {
    title: "Ascending Order",
    filterRef: "ascending",
  },
]

const PrincipalLeaderboard = (): React.ReactElement => {
  const [leaderboardData, loading, refetch, fetchMore] =
    useGetBusinessMemberLeaderboard()
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)

  const associateRanks = leaderboardData?.edges?.map((associateRank) => {
    return {
      id: associateRank.node.id,
      associateDetails: {
        userName: associateRank.node.userName,
        email: associateRank.node.email,
      },
      rankingData: {
        rank:
          associateRank.node.monthlyActivatedMerchants === 0
            ? "-"
            : associateRank.node.rank === 0
            ? "*"
            : associateRank.node.rank,
        evolution: associateRank.node.evolution,
        updatedAt: associateRank.node.updatedAt,
      },
      monthlyActivatedMerchants: associateRank.node.monthlyActivatedMerchants,
      monthlyTpv: associateRank.node.monthlyTpv,
      rowDisabled: associateRank.node.monthlyActivatedMerchants === 0,
    }
  })

  const handleFilterChange = (index: number) => {
    refetch({
      ordering: leaderboardFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreRankings = useCallback(() => {
    fetchMore({
      variables: {
        after: leaderboardData?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, leaderboardData?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = useMemo(
    () => [
      {
        name: "No.",
        accessor: "rankingData",
        columnAllowance: "minmax(30px, max-content)",
        showOnMobile: true,
        renderData: (
          index: number,
          rankingData: {
            rank: number | "-" | "*"
          }
        ) => (
          <RankingIndicator
            key={`current-rank-${index}`}
            rank={rankingData.rank}
          />
        ),
      },
      {
        name: "Reseller",
        accessor: "associateDetails",
        columnAllowance: "minmax(0, 2fr)",
        showOnMobile: true,
        renderData: (
          index: number,
          associateDetails: {
            userName: string
            email: string
          }
        ) => {
          return (
            <AssociateContainer
              key={`associate-${associateDetails.userName}-${index}`}
            >
              <p className="associate-name">{associateDetails.userName}</p>
              <p className="associate-email">{associateDetails.email}</p>
            </AssociateContainer>
          )
        },
      },
      {
        name: "Evolution",
        accessor: "rankingData",
        columnAllowance: "1fr",
        showOnMobile: true,
        renderData: (
          index: number,
          rankingData: {
            rank: number
            evolution: number
            updatedAt: string
          }
        ) => (
          <EvolutionIndicator
            key={`evolution-${index}`}
            rank={rankingData.rank}
            evolution={rankingData.evolution}
            lastUpdatedAt={rankingData.updatedAt}
          />
        ),
      },
      {
        name: "No. Merchants",
        accessor: "monthlyActivatedMerchants",
        columnAllowance: "1fr",
        renderData: (index: number, monthlyActivatedMerchants: number) => (
          <ActivatedMerchantsCount
            key={`merchantCount-${index}`}
            gridArea={"merchantCount"}
          >
            <b>{monthlyActivatedMerchants}</b> Activated{" "}
            <span className="show-on-mobile">merchants</span>
          </ActivatedMerchantsCount>
        ),
      },
      {
        name: "Monthly TPV",
        accessor: "monthlyTpv",
        columnAllowance: "1fr",
        renderData: (index: number, monthlyTpv: number) => (
          <RowTextField
            key={`tpv-${index}`}
            gridArea={"tpv"}
            className="mobile-align-right"
          >
            R {monthlyTpv}
          </RowTextField>
        ),
      },
    ],
    []
  )

  return (
    <>
      <Filter
        isPageFilter
        filterOptions={leaderboardFilters}
        onClick={handleFilterChange}
        activeIndex={selectedFilterIndex}
      />
      <Table
        headings={tableHeadings}
        rowData={associateRanks}
        loading={loading}
        emptyTableText="You have no associates to place on the leaderboard"
        allRowsLoaded={!leaderboardData?.pageInfo.hasNextPage}
        handleLoadMoreRows={handleFetchMoreRankings}
      />
    </>
  )
}

export default PrincipalLeaderboard
