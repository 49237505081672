import React, { useRef, useState } from "react"
import styled from "styled-components"

import { ToolTip } from "../../components"

const Ranking = styled.div`
  grid-area: position;
  line-height: 22px;
  padding-right: 5px;
`

const RankingTooltipText = styled.p<{
  singleLine: boolean
}>`
  max-width: 200px;
  line-height: ${({ singleLine }) => (singleLine ? "20px" : "14px")};
`

interface Props {
  rank: number | "-" | "*"
}

const RankingIndicator: React.FC<Props> = ({ rank }) => {
  const rankingIndicator = useRef<HTMLSpanElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)

  return typeof rank === "number" ? (
    <Ranking>
      <p>{rank}.</p>
    </Ranking>
  ) : (
    <div>
      <ToolTip
        open={showToolTip}
        referenceElement={rankingIndicator}
        displayRightSide
      >
        {rank === "-" ? (
          <RankingTooltipText singleLine={true}>
            This reseller is unranked.
          </RankingTooltipText>
        ) : (
          <RankingTooltipText singleLine={false}>
            This reseller&apos;s rank is currently being updated. Please check
            again later to see their new position.
          </RankingTooltipText>
        )}
      </ToolTip>
      <Ranking
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <span ref={rankingIndicator}>{rank}</span>
      </Ranking>
    </div>
  )
}

export default RankingIndicator
