import { Link } from "gatsby"
import React from "react"

import { useGetRankingsLastUpdatedAtTimestamp, useUser } from "../../auth/hooks"
import { PageHeader, PageNotificationBanner, PageGuard } from "../../components"
import Seo from "../../components/seo"
import PrincipalLeaderboard from "../../leaderboard/components/PrincipalLeaderboard"
import ResellerLeaderboard from "../../leaderboard/components/ResellerLeaderboard"
import { timeUntilDate } from "../../utils/date"

const Leaderboard = (): React.ReactElement => {
  const { user } = useUser()
  const [lastUpdatedAt] = useGetRankingsLastUpdatedAtTimestamp()
  return (
    <PageGuard verifyUserProfile>
      <Seo title="Leaderboard" />
      <PageHeader cypressTestId="leaderboard-page-header" title="Leaderboard" />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to view your{" "}
            {user?.profile?.profileType === "PRINCIPAL"
              ? "rank on the"
              : "associate"}{" "}
            leaderboard.
          </p>
        ) : user?.profile?.profileType === "PRINCIPAL" ? (
          <p>
            View your associate&apos;s leaderboard positions and movements since
            last month.
            <br />
            {lastUpdatedAt && (
              <span className="highlighted">
                Last updated: {timeUntilDate(lastUpdatedAt)}
              </span>
            )}
          </p>
        ) : (
          <p>
            View your leaderboard positions and movements since last month and
            see how you fare against other resellers.
            <br />
            {lastUpdatedAt && (
              <span className="highlighted">
                Last updated: {timeUntilDate(lastUpdatedAt)}
              </span>
            )}
          </p>
        )}
      </PageNotificationBanner>
      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        user?.profile?.profileType === "PRINCIPAL" ? (
          <PrincipalLeaderboard />
        ) : (
          <ResellerLeaderboard />
        )
      ) : null}
    </PageGuard>
  )
}

export default Leaderboard
