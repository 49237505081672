import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import {
  useGetIndividualLeaderboard,
  useGetRanking,
  useUser,
} from "../../auth/hooks"
import { Filter, Table as TableBase } from "../../components"
import { TableHeading } from "../../components/Table"
import EvolutionIndicator from "./EvolutionIndicator"
import RankingIndicator from "./RankingIndicator"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "position reseller evolution"
      "position province merchantCount ";
    align-items: start;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-areas: "position reseller evolution merchantCount province";
      align-items: center;
    }
  }
`

const ResellerName = styled.p`
  grid-area: reseller;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`

const RowTextField = styled.p<{
  gridArea: string
}>`
  line-height: 22px;
  grid-area: ${({ gridArea }) => gridArea};
  padding-right: 5px;
  text-align: left;

  &.mobile-align-right {
    text-align: right;
    white-space: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    &.mobile-align-right {
      text-align: left;
    }
  }
`

const ActivatedMerchantsCount = styled(RowTextField)`
  font-size: 12px;

  b {
    font-size: 14px;
  }

  .show-on-mobile {
    display: inline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;

    .show-on-mobile {
      display: none;
    }
  }
`

interface FilterOption {
  title: string
  filterRef: Reseller.IndividualRankScopeType
}

const leaderboardFilters: FilterOption[] = [
  {
    title: "Global Ranking",
    filterRef: "GLOBAL",
  },
  {
    title: "Provincial Ranking",
    filterRef: "PROVINCIAL",
  },
]

const ResellerLeaderboard = (): React.ReactElement => {
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const { user } = useUser()
  const [leaderboardData, loading, refetch, fetchMore] =
    useGetIndividualLeaderboard()
  const [userRankingData] = useGetRanking(false)

  const rankings = useMemo(() => {
    const tempRankings = leaderboardData?.edges.map((ranking) => {
      return {
        id: ranking.node.id,
        userName: ranking.node.userName,
        evolution: {
          evolution: ranking.node.evolution,
          rank:
            ranking.node.monthlyActivatedMerchants === 0
              ? "-"
              : ranking.node.rank === 0
              ? "*"
              : ranking.node.rank,
          updatedAt: ranking.node.updatedAt,
        },
        monthlyActivatedMerchants: ranking.node.monthlyActivatedMerchants,
        province: ranking.node.province !== null ? ranking.node.province : "-",
        rowFixed: false,
      }
    })

    const targetUserRanking = userRankingData?.find(
      (rank) =>
        rank.scopeType === leaderboardFilters[selectedFilterIndex].filterRef
    )

    const isUserRanked =
      targetUserRanking?.rank === null ||
      targetUserRanking?.monthlyActivatedMerchants === 0 ||
      targetUserRanking?.monthlyActivatedMerchants === null

    if (targetUserRanking && tempRankings) {
      tempRankings.push({
        id: `${user?.firstName} ${user?.lastName}`,
        userName: `${user?.firstName} ${user?.lastName}`,
        evolution: {
          evolution: targetUserRanking?.evolution,
          rank: isUserRanked
            ? "-"
            : targetUserRanking?.rank === 0
            ? "*"
            : targetUserRanking?.rank,
          updatedAt: targetUserRanking?.updatedAt,
        },
        monthlyActivatedMerchants: targetUserRanking?.monthlyActivatedMerchants,
        province:
          targetUserRanking.province !== null
            ? targetUserRanking.province
            : "-",
        rowFixed: true,
      })
    }
    return tempRankings
  }, [
    selectedFilterIndex,
    user?.firstName,
    user?.lastName,
    leaderboardData?.edges,
    userRankingData,
  ])

  const handleFilterChange = (index: number) => {
    refetch({
      scopeType: leaderboardFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreRankings = useCallback(() => {
    fetchMore({
      variables: {
        after: leaderboardData?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, leaderboardData?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = useMemo(
    () => [
      {
        name: "No.",
        accessor: "evolution",
        columnAllowance: "minmax(30px, max-content)",
        showOnMobile: true,
        renderData: (
          index: number,
          evolution: {
            rank: number | "-" | "*"
          }
        ) => (
          <RankingIndicator
            key={`current-rank-${index}`}
            rank={evolution.rank}
          />
        ),
      },
      {
        name: "Reseller",
        accessor: "userName",
        columnAllowance: "1fr",
        showOnMobile: true,
        renderData: (index: number, userName: string) => {
          return (
            <ResellerName key={`reseller-${userName}-${index}`}>
              {userName}
            </ResellerName>
          )
        },
      },
      {
        name: "Evolution",
        accessor: "evolution",
        columnAllowance: "1fr",
        showOnMobile: true,
        renderData: (
          index: number,
          evolution: {
            evolution: number
            rank: number | "-" | "*"
            updatedAt: string
          }
        ) => (
          <EvolutionIndicator
            key={`evolution-${index}`}
            rank={evolution.rank}
            evolution={evolution.evolution}
            lastUpdatedAt={evolution.updatedAt}
          />
        ),
      },
      {
        name: "No. Merchants",
        accessor: "monthlyActivatedMerchants",
        columnAllowance: "1fr",
        renderData: (index: number, monthlyActivatedMerchants: number) => (
          <ActivatedMerchantsCount
            key={`monthlyActivatedMerchants-${index}`}
            gridArea={"merchantCount"}
            className="mobile-align-right"
          >
            <b>{monthlyActivatedMerchants}</b> Activated{" "}
            <span className="show-on-mobile">merchants</span>
          </ActivatedMerchantsCount>
        ),
      },
      {
        name: "Province",
        accessor: "province",
        columnAllowance: "1fr",
        renderData: (index: number, province: string) => (
          <RowTextField key={`province-${index}`} gridArea={"province"}>
            {province}
          </RowTextField>
        ),
      },
    ],
    []
  )

  return (
    <>
      <Filter
        isPageFilter
        filterOptions={leaderboardFilters}
        onClick={handleFilterChange}
        activeIndex={selectedFilterIndex}
      />
      <Table
        headings={tableHeadings}
        rowData={rankings}
        loading={loading}
        emptyTableText="There is not enough leaderboard data to display any rankings"
        allRowsLoaded={!leaderboardData?.pageInfo.hasNextPage}
        handleLoadMoreRows={handleFetchMoreRankings}
      />
    </>
  )
}

export default ResellerLeaderboard
