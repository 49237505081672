import React, { useRef, useState, useCallback } from "react"
import styled from "styled-components"

import { ToolTip } from "../../components"
import { timeUntilDate } from "../../utils/date"

const Evolution = styled.div`
  grid-area: evolution;
  max-width: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  line-height: 22px;
`

const EvolutionContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-start;
  }
`

const EvolutionTooltipText = styled.p`
  max-width: 200px;
  line-height: 16px;

  span {
    color: ${({ theme }) => theme.palette.orangePeel};
  }
`

interface Props {
  rank: number | "-" | "*"
  evolution: number
  lastUpdatedAt: string
}

const EvolutionIndicator: React.FC<Props> = ({
  rank,
  evolution,
  lastUpdatedAt,
}) => {
  const evolutionIndicator = useRef<HTMLImageElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)

  const getEvolutionIndicator = useCallback(() => {
    if (evolution !== null) {
      if (evolution > 0) {
        return "/images/leaderboard/evolution-indicator-positive.svg"
      } else if (evolution === 0) {
        return "/images/leaderboard/evolution-indicator-neutral.svg"
      } else {
        return "/images/leaderboard/evolution-indicator-negative.svg"
      }
    } else {
      return "/images/leaderboard/evolution-indicator-no-data.svg"
    }
  }, [evolution])

  return (
    <>
      <ToolTip
        open={showToolTip}
        referenceElement={evolutionIndicator}
        customOffsetX={50}
      >
        {evolution === null ? (
          <EvolutionTooltipText>
            There is not enough data to show this reseller&apos;s evolution
            since last month.
          </EvolutionTooltipText>
        ) : evolution === 0 ? (
          <EvolutionTooltipText>
            Leaderboard position has not changed since last month. Ranking last
            updated: <span>{timeUntilDate(lastUpdatedAt)}</span>.
          </EvolutionTooltipText>
        ) : (
          <EvolutionTooltipText>
            Leaderboard position {evolution > 0 ? "rose" : "dropped"} from{" "}
            {typeof rank === "number" ? rank + evolution : evolution} last month
            to {rank}. Ranking last updated:{" "}
            <span>{timeUntilDate(lastUpdatedAt)}</span>.
          </EvolutionTooltipText>
        )}
      </ToolTip>
      <EvolutionContainer>
        <Evolution
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
        >
          <p>
            {evolution === null
              ? "N/A"
              : evolution > 0
              ? `+${evolution}`
              : evolution}
          </p>
          <img
            ref={evolutionIndicator}
            src={getEvolutionIndicator()}
            alt="Evolution indicator"
          />
        </Evolution>
      </EvolutionContainer>
    </>
  )
}

export default EvolutionIndicator
